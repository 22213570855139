import React from 'react';
import css from './TopbarPromoBar.module.css';
import { FormattedMessage } from '../../../../util/reactIntl';
import { NamedLink } from '../../../../components';

export default function TopbarPromoBar({ isAuthenticated }) {

  return (
    <div className={css.promoBar}>
      <span className={css.promoText}>Don't Miss Out: 0% Seller Fees for a Limited Time!</span>
      {!isAuthenticated && (
        <NamedLink name="SignupPage" className={css.signUpText}>
          <span className={css.topbarLinkLabel}>
            <FormattedMessage id="TopbarDesktop.signup" />
          </span>
        </NamedLink>
      )}
    </div>
  );
}
